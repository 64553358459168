exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-tsx": () => import("./../../../src/pages/journal.tsx" /* webpackChunkName: "component---src-pages-journal-tsx" */),
  "component---src-pages-philosophie-tsx": () => import("./../../../src/pages/philosophie.tsx" /* webpackChunkName: "component---src-pages-philosophie-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-wir-tsx": () => import("./../../../src/pages/wir.tsx" /* webpackChunkName: "component---src-pages-wir-tsx" */),
  "component---src-templates-projekt-tsx": () => import("./../../../src/templates/projekt.tsx" /* webpackChunkName: "component---src-templates-projekt-tsx" */)
}

